import React from "react";
import Layout from "../../components/Layout";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import "../../templates/galerie.css";

export const GalleryPageTemplate = ({ galleries }) => (
  <Layout>
    <Container>
      <h1>Galerie</h1>
      <Row className="gallery">
        {galleries.map((g, i) => (
          <Col md={3} xs={6} key={i}>
            <Card>
              {g.mainImage ? (
                <Img
                  className="card-img-top"
                  fluid={g.mainImage.childImageSharp.fluid}
                  alt={g.title}
                />
              ) : (
                ""
              )}
              <CardBody>
                <CardTitle>{g.title}</CardTitle>
                <CardSubtitle>{g.description}</CardSubtitle>
                <Link to={g.slug} className="stretched-link"></Link>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  </Layout>
);

export default ({ data }) => {
  const galleries = data.allMarkdownRemark.edges
    .map((g) => ({ ...g.node.frontmatter, slug: g.node.fields.slug }))
    .sort((a, b) => new Date(a.date) - new Date(b.date));

  return <GalleryPageTemplate galleries={galleries} />;
};

export const galleryQuery = graphql`
  query GalleryPageQuery {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "galerie" } } }
    ) {
      edges {
        node {
          fileAbsolutePath
          fields {
            slug
          }
          frontmatter {
            title
            date
            description
            mainImage {
              childImageSharp {
                fluid(maxWidth: 400, quality: 40) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
